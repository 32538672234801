import { ApolloClient, createHttpLink, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getGraphqlUrl } from "./baseURL";

export const GraphQlClient = (currentUser) => {

  const customFetch = (uri, options) => {
    const { timeout = 65000 } = options;
    return new Promise((resolve, reject) => {
      fetch(uri, options).then(resolve).catch(reject);
      if (timeout) {
        const e = new Error("Network request failed");
        setTimeout(() => reject(e), timeout);
      }
    });
  };

  const httpLink = createHttpLink({
    uri: getGraphqlUrl(),
    fetch: (uri, options) => {
      return customFetch(uri, options);
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${currentUser.jwtIdToken.idToken}`,
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return client;
};