import gql from 'graphql-tag';
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from '@apollo/client';
import {
    Paper,
    CircularProgress,
    Typography,
    Container,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Grid,
    Button,
    selectClasses
 } from '@mui/material';
 import { DataGrid, GridToolbar } from '@mui/x-data-grid';

 export const PouchPrint = () => {
    //Get pouch print data from graphql query
    const GET_POUCH_PRINT = gql`
        query PouchPrint($drawingNumber: String, $revision: String, $datePrinted: DateTime, $status: String) {
            pouchPrints(drawingNumber: $drawingNumber, revision: $revision, datePrinted: $datePrinted, status: $status) {
                id,
                drawingNumber,
                revision,
                datePrinted,
                notes,
                status {
                    status
                },
                shopOrderId,
                dateAdded
            }
        }
    `;

    const GET_POUCH_PRINTERS = gql`
        query PouchPrint {
            pouchPrinters {
                id,
                printerName
            }
        }
    `;

    const PRINT_POUCH_PRINT_MUTATION = gql`
        mutation PrintPouchPrint($pouchPrintIds: [Int!], $printerId: Int!) {
            printPouchPrints(pouchPrintIds: $pouchPrintIds, printerId: $printerId) {
                id,
                drawingNumber,
                revision,
                datePrinted,
                notes,
                status {
                    status
                },
                shopOrderId,
                dateAdded
            }
        }
    `;

    //Run query where status and date printed are null
    const pouchPrintQuery = useQuery(GET_POUCH_PRINT, {variables: {dateAdded: new Date()}});
    const pouchPrinters = useQuery(GET_POUCH_PRINTERS);
    const [printPouchPrintsMutation, printPouchPrintsResult] = useMutation(PRINT_POUCH_PRINT_MUTATION);

    const [selectedPouchPinter, setSelectedPouchPrinter] = useState(1);
    const [selectedDocuments, setSelectedDocuments] = useState([]);

    

    useEffect(() => {
        if(pouchPrinters.data) {
            setSelectedPouchPrinter(pouchPrinters.data.pouchPrinters[0].id);
        }
    }, [pouchPrinters.data]);

    const onSelectedRowsChange = (ids) => {
        setSelectedDocuments(ids);
    }

    const onPrint = () => {
        //Need to remove __typename property that gets added to query objects before sending, as mutation will fail if that property exists
        printPouchPrintsMutation({variables: {pouchPrintIds: selectedDocuments, printerId: selectedPouchPinter}});
    }

    return(
        //Display pouch print query results in a data grid
        <Container disableGutters maxWidth={false}>
            <Typography variant='h5'>Pouch Print</Typography>
            <Grid 
                container 
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Button
                        variant="contained"
                        disabled={selectedDocuments.length === 0 || printPouchPrintsResult.loading}
                        onClick={onPrint}
                    >
                        Print
                    </Button>
                </Grid>
                <Grid item>
                    {!pouchPrinters.loading && pouchPrinters.data &&
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }}>
                        <InputLabel>Printer</InputLabel>
                        <Select
                            label="Printer"
                            value={selectedPouchPinter}
                            onChange={(e) => {setSelectedPouchPrinter(e.target.value)}}
                        >
                            {pouchPrinters.data.pouchPrinters.map((item, index) => {
                                return(
                                    <MenuItem key={index} value={item.id}>{item.printerName}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                }
                </Grid>
            </Grid>
            
            
            <Paper sx={{height: 775, width: '100%'}}>
                {/* if pouch print query is loading, display a circular progress, else, display pouch print query results} */}
                {(pouchPrintQuery.data && pouchPrintQuery.data.pouchPrints) ?
                    <DataGrid
                        columns={[
                            { field: 'id', headerName: 'ID' },
                            { field: 'drawingNumber', headerName: 'Drawing Number', flex: 1 },
                            { field: 'revision', headerName: 'Revision', flex: 1 },
                            { field: 'shopOrderId', headerName: 'Shop Order', flex: 1, sortable: true },
                            { 
                                field: 'dateAdded', 
                                headerName: 'Date Added', 
                                flex: 1,
                                valueGetter: (params) => {
                                    if(params.row.dateAdded) {
                                        return new Date(params.row.dateAdded).toLocaleString();
                                    }
                                }
                            },
                            { 
                                field: 'datePrinted', 
                                headerName: 'Date Printed', 
                                flex: 1,
                                valueGetter: (params) => {
                                    if(params.row.datePrinted) {
                                        return new Date(params.row.datePrinted).toLocaleString();
                                    }
                                }
                            },
                            { 
                                field: 'status', 
                                headerName: 'Status', 
                                flex: 1,
                                valueGetter: (params) => {
                                    if(params.row.status) {
                                        if(params.row.status.status) {
                                            return params.row.status.status;
                                        }
                                    }
                                }
                            }
                        ]}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        rows={pouchPrintQuery.data.pouchPrints}
                        //loading={pouchPrintQuery.loading}
                        //error={pouchPrintQuery.error}
                        pageSize={50}
                        rowsPerPageOptions={[50, 100, 200, 500]}
                        checkboxSelection
                        onSelectionModelChange={onSelectedRowsChange}
                    />
                :
                    <CircularProgress />
                }
            </Paper>
        </Container>
        
    );
 };